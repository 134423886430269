import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  scrollBehavior(to) {
    if (to.hash) {
      return
    }
    return { x: 0, y: 0, behavior: 'smooth' }
  },
  routes: [
    { path: '/', component: () => import('@/pages/index') },
    { path: '/auth', component: () => import('@/pages/auth') },
    { path: '/activation', component: () => import('@/pages/activation') },
    { path: '/renewal', component: () => import('@/pages/renewal') },
    { path: '/change', component: () => import('@/pages/change') },
    { path: '/faq', component: () => import('@/pages/faq') },
    { path: '/articles', component: () => import('@/pages/articles') },
    { path: '/articles/:id', component: () => import('@/pages/articles/_id.vue') },
    { path: '/collaboration', component: () => import('@/pages/collaboration') },
    { path: '/privacy-policy', component: () => import('@/pages/privacy-policy') },
    { path: '/product/:id', component: () => import('@/pages/product') },
    { path: '/contacts', component: () => import('@/pages/contacts') },
    { path: '/ordering', component: () => import('@/pages/ordering') },
    { path: '/refusal', component: () => import('@/pages/refusal') },
    { path: '/requisites', component: () => import('@/pages/requisites') },
    { path: '/information', component: () => import('@/pages/information') },
    { path: '/payment-rules', component: () => import('@/pages/payment-rules') },
    { path: '/agreements', component: () => import('@/pages/agreements') },
    { path: '/login', beforeEnter() { location.href = '/login' } }
  ]
})

export default router;